import React from 'react';
import Cookie from 'js-cookie';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
// import { Image, Card, Typography, Row, Col, Space, Input, Skeleton, List, Tooltip, Button, Breadcrumb, Grid } from 'antd';
import { Image, Card, Typography, Row, Col, Space, Skeleton, List, Tooltip, Breadcrumb, Grid, Carousel } from 'antd';
import { SafetyOutlined, EnvironmentOutlined, CheckOutlined, LeftCircleOutlined, PlayCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import Loading from '../../components/Loading';
import EmptyLogo from '../../assets/img/EmptyLogo.png';
import Lightbox from "yet-another-react-lightbox";
import Video from "../../../node_modules/yet-another-react-lightbox/dist/plugins/video";
import "../../../node_modules/yet-another-react-lightbox/dist/styles.css";
import CButton from '../Button'

const { Title, Text } = Typography
// const { Search } = Input
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function JobList(props) {
  // const { getCompany, getJob, history, onSearch, onChangePagination, handleFavorite } = props
  const { t, getCompany, getJob, history, onChangePagination, handleFavorite, mediaRef, previousMedia, nextMedia, handleOpenLightbox, openLightbox, handleCloseLightbox, slides } = props
  const { xs } = GetBreakPoint()

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    history.push('/login')
  }

  if(getCompany.loading){
    return <Loading />
  }

  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], `${getCompany?.data?.name} | Jobshub`);
  }

  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 4
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1
  //   }
  // };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: xs ? 1 : 3,
    slidesToScroll: 1
  };

  const mergeListCompanyMedia = getCompany?.data?.listMedia || []
  if(getCompany?.data?.videoUrl || getCompany?.data?.listMedia){
    mergeListCompanyMedia.unshift({id: getCompany?.data?.videoUrl, fileExtension: "mp4", fileName: getCompany?.data?.companyVideo, fileUrl: getCompany?.data?.videoUrl})
  }
  const listMediaAll = getCompany?.data?.videoUrl || getCompany?.data?.listMedia ? [...new Set([...new Map(mergeListCompanyMedia.map((m) => [m.id, m])).values()])] : []

  return (
    <React.Fragment>
      <Helmet>
        <title>{getCompany?.data?.name} | Jobshub</title>
      </Helmet>
      <Lightbox
        open={openLightbox}
        close={handleCloseLightbox}
        plugins={[Video]}
        slides={slides}
      />
      <Row className='mb-32'>
        <Col sm={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb className="mb-16 mt-16" style={{ paddingLeft: xs ? 16 : 0 }}>
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/job' : '/'}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Companies</Breadcrumb.Item>
            <Breadcrumb.Item>{getCompany?.data?.name}</Breadcrumb.Item>
          </Breadcrumb>

          <Card className="mb-16 mt-16" bordered={false}>
            <Row justify="space-around" align="middle" gutter={16}>
              <Col className="width-100" xs={24} sm={8} style={{ textAlign: '-webkit-center' }}>
                <Card style={{ width: 140, height: 140, backgroundColor: '#fff', boxShadow: '1px 1px 8px #cccccc' }} bodyStyle={{ height: '100%', display: 'grid' }}>
                  <Image
                    src={getCompany.data.logoUrl ? getCompany.data.logoUrl : EmptyLogo}
                    preview={false}
                    width={'100%'}
                    height={90}
                    style={{ objectFit: 'contain' }}
                  />
                </Card>
                <Title className="dark-color" style={{ marginTop: 10 }} level={5}>{getCompany.data.name} {getCompany.data.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Title>
              </Col>
              <Col xs={24} sm={8}>
                <Space className="width-100" direction="vertical">
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.website')} </Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      {
                        getCompany.data.website ?
                          <a className="web-company dark-color" href={getCompany.data.website.slice(0,8) === 'https://' || getCompany.data.website.slice(0,7) === 'http://' ? getCompany.data.website : `http://${getCompany.data.website}`} target="_blank" rel="noreferrer">{getCompany.data.website}</a>
                        :
                          <Text className="dark-color">-</Text>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.industry')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.jobIndustryTitle ? getCompany.data.jobIndustryTitle : '-'}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.Specialties')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.specialties ? getCompany.data.specialties : '-'}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.companySize')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.totalEmployee ? getCompany.data.totalEmployee : '-'}</Text>
                    </Col>
                  </Row>
                </Space>
              </Col>
              <Col className={xs ? 'mt-8' : null} xs={24} sm={8}>
                <Space className="width-100" direction="vertical">
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.address')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.address ? getCompany.data.address : '-'}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.city')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.cityName ? getCompany.data.cityName : '-'}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.province')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.provinceName ? getCompany.data.provinceName : '-'}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} sm={10} xl={6}>
                      <Text className="dark-color">{t('application.form.country')}</Text>
                    </Col>
                    <Col xs={16} sm={14} xl={18}>
                      <Text className="dark-color">{getCompany.data.countryName ? getCompany.data.countryName : '-'}</Text>
                    </Col>
                  </Row>
                </Space>
              </Col>
              <Col className='mt-16' span={24}>
                <Space direction='vertical'>
                  <Text className="dark-color" strong>{t('application.form.overview')}</Text>
                  <Text className="dark-color">{getCompany.data.about ? getCompany.data.about : '-'}</Text>
                </Space>
              </Col>
              {
                getCompany?.data?.listCompanyImage?.length > 0 ? 
                  <Col span={24}>
                    <Row>
                      <Col className='mt-16 mb-16' span={24}>
                        <Text className="dark-color fs-16" style={{ fontWeight: 500 }}>{t('application.form.company')}</Text>
                      </Col>
                      {/* <Col span={24}>
                        <Carousel
                          arrows={getCompany?.data?.listCompanyImage?.length > 1}
                          swipeable={getCompany?.data?.listCompanyImage?.length > 1}
                          draggable={false}
                          // showDots={true}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          // autoPlay={true}
                          // autoPlaySpeed={2000}
                          // keyBoardControl={true}
                          // transitionDuration={5000}
                        >
                          {
                            getCompany?.data?.listCompanyImage?.map(item =>
                              <Row>
                                <Col span={20} offset={2}>
                                  <Image
                                    src={item.fileUrl}
                                    width={'100%'}
                                    height={174}
                                    style={{ objectFit: 'cover' }}
                                  />
                                </Col>
                              </Row>
                            )
                          }
                        </Carousel>
                      </Col> */}
                      {
                          listMediaAll?.length > 3 ?
                            <Col className='text-align-center' xs={2} md={1}>
                              <LeftCircleOutlined className='dark-color' onClick={previousMedia} style={{ fontSize: xs ? 26 : 32, marginTop: xs ? 75 : 70 }} />
                            </Col>
                          : null
                        }
                        <Col className='text-align-center' xs={20} md={22}>
                          {
                            listMediaAll?.length > 3 ?
                              <Carousel ref={mediaRef} {...settings}>
                                {
                                  listMediaAll?.map((item,i) => 
                                    <Row key={i} gutter={8}>
                                      <Col span={24}>
                                        <div>
                                          <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(listMediaAll,i)} bodyStyle={{ padding: 8 }}>
                                            {
                                              i === 0 ?
                                                <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                                                  <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                                                </Card>
                                              :
                                                <Image
                                                  src={item.fileUrl}
                                                  preview={false}
                                                  width={'100%'}
                                                  height={160}
                                                  style={{ objectFit: 'cover' }}
                                                />
                                            }
                                          </Card>
                                        </div>
                                      </Col>
                                    </Row>
                                  )
                                }
                              </Carousel>
                            :
                              <Row gutter={8}>
                                {
                                  listMediaAll?.map((item,i) => 
                                    <Col key={i} xxl={6} md={8}>
                                      <div>
                                        <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(listMediaAll,i)} bodyStyle={{ padding: 8 }}>
                                          {
                                            i === 0 ?
                                              <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                                                <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                                              </Card>
                                            :
                                              <Image
                                                src={item.fileUrl}
                                                preview={false}
                                                width={'100%'}
                                                height={160}
                                                style={{ objectFit: 'cover' }}
                                              />
                                          }
                                        </Card>
                                      </div>
                                    </Col>
                                  )
                                }
                              </Row>
                          }
                        </Col>
                        {
                          listMediaAll?.length > 3 ?
                            <Col className='text-align-center' xs={2} md={1}>
                              <RightCircleOutlined className='dark-color' onClick={nextMedia} style={{ fontSize: xs ? 26 :  32, marginTop: xs ? 75 : 70 }} />
                            </Col>
                          : null
                        }
                    </Row>
                  </Col>
                : 
                  null
              }
            </Row>
          </Card>
          
          {
            getJob?.loading ?
              <Skeleton.Input style={{ width: '100%' }} active />
            :
              <Text className="dark-color fs-13 mb-32" style={{ paddingLeft: xs ? 16 : 0 }}>{`Showing ${getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : (getJob?.data?.meta?.page*getJob?.data?.meta?.perpage)-(getJob?.data?.meta?.perpage-1)}-${getJob?.data?.meta?.page === getJob?.data?.meta?.pages || getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : getJob?.data?.meta?.page*getJob?.data?.meta?.perpage} of ${getJob?.data?.meta?.total} job openings`}</Text>
          }
          {
            getJob?.loading ?
              <Card className="mb-8" style={{ width: '100%' }}>
                <Skeleton active avatar />
              </Card>
            :
              <List
                className="mt-16"
                size="large"
                pagination={{
                  style: { textAlign: 'center', paddingBottom: 24 },
                  pageSize: getJob?.data?.meta?.perpage,
                  total: getJob?.data?.meta?.total,
                  current: getJob?.data?.meta?.page,
                  onChange: (pageNumber) => onChangePagination(pageNumber)
                }}
                dataSource={getJob?.data?.data}
                renderItem={item => (
                  <List.Item key={item.id}>
                    <Row className="width-100" gutter={16}>
                      <Col className="col-img-job" xs={24} sm={4} md={4}>
                        <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                          <Image
                            src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                            preview={false}
                            width={'100%'}
                            height={100}
                            style={{ objectFit: 'contain' }}
                          />
                        </Link>
                      </Col>
                      <Col xs={24} sm={13} md={12}>
                        <Space direction="vertical" size={0}>
                          <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                            <Text className="title-job dark-color fs-17">{item.title}</Text>
                          </Link>
                          <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                            <Text className="company-job fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                          </Link>
                          <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                          <Text className="fs-13 primary-color">
                            {
                              item.isDisplaySalary ?
                                item.maxSalary ? 
                                  `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                : 
                                  `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                              :
                                'Salary not disclosed'
                            }
                          </Text>
                        </Space>
                      </Col>
                      <Col xs={24} sm={7} md={8}>
                        <Space className={ xs ? "width-100 float-left" : "width-100 float-right"} direction="vertical" size={16}>
                          <Text className="fs-11 dark-color float-right">{`${t('application.form.postedOn')} ${item.postDate}`}</Text>
                          {
                            Cookie.get('user') ?
                              <Space className='float-right' size={8}>
                                <Tooltip title={item.isFavorite ? 'Already added to favorites' : 'Add to favorites' }>
                                  <CButton type="primary-icon" icon={item.isFavorite ? "StarFilled" : "StarOutlined" } action={() => handleFavorite(item.id, item.isFavorite)} ghost/>
                                  {/* <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost /> */}
                                </Tooltip>
                                {
                                  item.isApply ?
                                    <Text className="float-right fs-14 primary-color"><CheckOutlined /> {t('application.form.applied')}</Text>
                                  :
                                    <Link to={`/candidate/job/detail/${item.slug}`}>
                                      <CButton type="primary" className="float-right" title={t('application.form.apply')}/>
                                      {/* <Button type="primary" className="float-right border-radius-6">{t('application.form.apply')}</Button> */}
                                    </Link>
                                }         
                              </Space>
                            :
                                <CButton type="primary" className={ xs ? "float-left" : "float-right"} action={() => onClickApply(`/job/detail/${item.slug}`)} title={t('landingPage.buttonApply')}/>
                              // <Button type="primary" className={ xs ? "float-left border-radius-6" : "float-right border-radius-6"} onClick={() => onClickApply(`/job/detail/${item.slug}`)}>Apply</Button>
                          }
                        </Space>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}