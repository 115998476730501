import React from 'react';
import { path } from '../../../../config/index'
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import "../../../../../node_modules/video-react/dist/video-react.css"
import moment from 'moment';
import { withTranslation } from 'react-i18next';
// import { RightCircleOutlined, SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import { Row, Col, Typography, Card, Button, Space, Divider, Modal, Steps, Form, Skeleton, Tooltip, Tabs, Image, List, Dropdown, Menu, Collapse, Carousel, message } from 'antd';
import Lightbox from "yet-another-react-lightbox";
import Video from "../../../../../node_modules/yet-another-react-lightbox/dist/plugins/video";
import "../../../../../node_modules/yet-another-react-lightbox/dist/styles.css";
import Contact from './contact'
import PersonalInfo from './personalInfo'
import Education from './education'
import Review from './review'
import Background from '../../../../assets/img/job/m-background.png'
import { CheckOutlined, StarOutlined, StarFilled, EnvironmentOutlined, SafetyOutlined, LeftCircleFilled, RightCircleFilled, ShareAltOutlined, FacebookOutlined, TwitterOutlined, WhatsAppOutlined, LinkOutlined, PlusOutlined, MinusOutlined, PlayCircleOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
const { Text } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`)
  const Component = icons[type]
  return <Component {...rest} />
}

export function DetailJobMobile(props) {
  const {
    submitLoading,
    getJobDetail: { data },
    showModal,
    getSteps,
    handleCancel,
    visible,
    current,
    prev,
    onNext,
    onFinish,
    onUpdate,
    formRef,
    editContact,
    editPersonal,
    editEducation,
    handleFavorite,
    next,
    previous,
    nextMedia,
    previousMedia,
    sliderRef,
    mediaRef,
    openLightbox,
    handleOpenLightbox,
    handleCloseLightbox,
    slides,
    t
  } = props
  const jobDestination = 
    data.jobDestinationParentId ? 
      `${data.jobDestinationName}, ${data.jobDestinationParentName}` 
    : 
      data.jobDestinationName
  const salary = 
    data.isDisplaySalary ? 
      data.isFixSalary ? 
        `${data.salary ? data.salary.toLocaleString() : null} ${data.currencyCode}`
      : 
        `${data.salary ? data.salary.toLocaleString() : null} - ${data.maxSalary ? data.maxSalary.toLocaleString() : null} ${data.currencyCode}` 
    :
      'Salary not disclosed'
  const targetDate = data.targetDate ? `${t('jobDetail.applyBefore')} ${data.targetDate}` : null
  const placementType = 
    data.placementType === 'INTERNATIONAL' ?
      'International'
    :
      'Domestic'
  const experienceLevel = 
    data.experienceLevel === 'ENTRY_LEVEL' ?
      'Entry Level'
    : data.experienceLevel === 'ASSOCIATE' ?
      'Associate'
    : data.experienceLevel === 'MID_SENIOR' ?
      'Mid-Senior Level'
    : data.experienceLevel === 'DIRECTOR' ?
      'Director'
    :
      'Executive'
  const jobType = 
    data.jobType === 'FULL_TIME' ? 
    'Full Time' 
  : data.jobType === 'PART_TIME' ?
    'Part Time'
  : data.jobType === 'FREELANCE' ?
    'Freelance'
  : data.jobType === 'INTERNSHIP' ?
    'Internship'
  : data.jobType === 'VOLUNTEER' ?
    'Volunteer'
  : data.jobType === 'TALENT_POOL' ?
    'Talent Pool'
  :
    '-'
  const paymentType =
    data.paymentType === 'DAILY' ?
      'Daily'
    : data.paymentType === 'WEEKLY' ?
      'Weekly'
    : data.paymentType === 'MONTHLY' ?
      'Monthly'
    : data.paymentType === 'YEARLY' ?
      'Yearly'
    :
      'Per Project'
  const isRemote = data.isRemote ? '(Possible to work from home)' : null
  const isContractExtendable = data.isContractExtendable ? '(Extendable)' : '(No Extendable)'
  const isPaidAnnualLeave = data.isPaidAnnualLeave ? '(Paid)' : '(Unpaid)'
  const isWorkingOvertime = data.isWorkingOvertime ? 'Yes' : 'No'
  const gender = 
    data.gender === 'BOTH' ? 
      'Male and Female' 
    : data.gender === 'MALE' ? 
      'Male' 
    : 
      'Female'
  const skill = data.skill ? data.skill.join(', ') : data.skill

  const mergeListCompanyMedia = data.listCompanyMedia || []
  if(data.companyVideoUrl || data.listCompanyMedia){
    mergeListCompanyMedia.unshift({id: data.companyVideoUrl, fileExtension: "mp4", fileName: data.companyVideo, fileUrl: data.companyVideoUrl})
  }
  const listCompanyMediaAll = data.companyVideoUrl || data.listCompanyMedia ? [...new Set([...new Map(mergeListCompanyMedia.map((m) => [m.id, m])).values()])] : []

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
    message.success(t('copiedLink'));
  }

  const menu = (url) => (
    <Menu>
      <Menu.Item>
        <FacebookShareButton 
          url={url}
          // quote={"Jobshub - Job Detail"}
          // hashtag="#jobshub"
        >
          <Space>
            <FacebookOutlined />
            <Text>Facebook</Text>
          </Space>
        </FacebookShareButton>
      </Menu.Item>
      {/* <Menu.Item><InstagramOutlined /> Instagram</Menu.Item> */}
      <Menu.Item>
        <WhatsappShareButton 
          url={url}
          title=""
          separator=""
        >
          <Space>
            <WhatsAppOutlined />
            <Text>Whatsapp</Text>
          </Space>
        </WhatsappShareButton>
      </Menu.Item>
      <Menu.Item>
        <TwitterShareButton 
          url={url}
          // title=""
          // via=""
          // hashtags=""
          // related=""
        >
          <Space>
            <TwitterOutlined />
            <Text>Twitter</Text>
          </Space>
        </TwitterShareButton>
      </Menu.Item>
      <Menu.Item onClick={() => handleCopy(url)}><LinkOutlined /> {`${t('jobDetail.copyLink')}`}</Menu.Item>
    </Menu>
  );

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    props.history.push('/login-or-register')
  }

  const onClickToDetail = (slug) => {
    window.location.href = Cookie.get('user') ? `/candidate/job/detail/${slug}` : `/job/detail/${slug}`
    // props.history.push(`/candidate/job/detail/${slug}`)
  }

  if(Cookie.get('user') || getSteps?.loading){
    <Skeleton active />
  }
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <React.Fragment>
      <Lightbox
        open={openLightbox}
        close={handleCloseLightbox}
        plugins={[Video]}
        slides={slides}
      />
      <Row>
        <Col span={24}>
          <Card 
            className="mb-16 box-shadow"
            bordered={false} 
            bodyStyle={{ padding: 0 }}
          >
            <Row>
              <Col span={24}>
                <Image 
                  src={Background}
                  preview={false}
                  width={'100%'}
                  height={90}
                  style={{ objectFit: 'cover' }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: -70 }}>
                <Space direction="vertical" size={4} style={{ marginLeft: 20 }}>
                  {/* <Link to={Cookie.get('user') ? `/candidate/company/${data.companyId}/${data.companyType}` : `/company/${data.companyId}/${data.companyType}`}> */}
                    <Card style={{ width: 100, height: 100, marginBottom: 16 }} bodyStyle={{ padding: 4 }}>
                      <Image
                        src={data.companyLogoUrl ? data.companyLogoUrl : EmptyLogo}
                        preview={false}
                        width={90}
                        height={90}
                        style={{ objectFit: 'contain' }}
                      />
                    </Card>
                  {/* </Link> */}
                  <Text className="fs-17 dark-color" strong>{data.title}</Text>
                  <Text className="fs-13 dark-color">{`${t('jobDetail.postedOn')} ${data.dateOpened}`}</Text>
                  {
                    data.status === 'ACTIVE' ?
                      <Text className="fs-13 primary-color">{targetDate}</Text>
                    : null
                  }
                  <Link to={Cookie.get('user') ? `/candidate/company/${data.companyId}/${data.companyType}` : `/company/${data.companyId}/${data.companyType}`}>
                    <Text className="title-job fs-14 primary-color cursor-pointer">{data.companyName} {data.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                  </Link>
                  <Text className="fs-14 primary-color">{jobDestination}</Text>
                  <Text className="fs-14 dark-color">{data.jobIndustryName}</Text>
                  <Text className="fs-14 primary-color">{salary}</Text>
                  <Text className="fs-14 dark-color">{data.agencyName ? `${t('jobDetail.recruitment')} ${data.agencyName}` : null}</Text>
                </Space>
              </Col>
            </Row>

            <Row style={{ marginLeft: 20, marginRight: 20 }}>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>

            <Row className="mb-16" style={{ marginLeft: 20, marginRight: 20 }}>
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text className="fs-13 dark-color">{`${t('jobDetail.position')}`}</Text>
                    <Text className="fs-14 dark-color" strong>{data.jobPositionName}</Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-13 dark-color">{`${t('jobDetail.placementType')}`}</Text>
                    <Text className="fs-14 dark-color" strong>{placementType}</Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-13 dark-color">{`${t('jobDetail.experienceLevel')}`}</Text>
                    <Text className="fs-14 dark-color" strong>{experienceLevel}</Text>
                  </Space>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text className="fs-13 dark-color">{`${t('jobDetail.jobType')}`}</Text>
                    {
                      jobType === 'Talent Pool' ?
                        <Tooltip placement="top" title="The Talent Pool is a shortlist of candidates who may be suited for various job opportunities in the future. By registering in the employer’s Talent Pool, you are included in the potential list which will be evaluated periodically and can be a priority candidate for a job even before the vacancy is published.">
                          <Text className="fs-14 dark-color" strong>{jobType} {isRemote}</Text>
                        </Tooltip>
                      :
                        <Text className="fs-14 dark-color" strong>{jobType} {isRemote}</Text>
                    }
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-13 dark-color">{`${t('jobDetail.paymentType')}`}</Text>
                    <Text className="fs-14 dark-color" strong>{paymentType}</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
            
            <Row style={{ marginLeft: 20, marginRight: 20 }}>
              <Col span={24}>
                {
                  (data.id === 106 || data.id === 123) && Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                    <Divider />
                  : (data.id === 106 || data.id === 123) && Cookie.get('email')?.split("@")[1] !== 'student.binawan.ac.id' ?
                    null
                  :
                    <Divider />
                }
              </Col>
            </Row>

            <Row style={{ marginLeft: 20, marginRight: 20, marginBottom: 24 }}>
              <Col span={24}>
                <Space className="float-right" size={4}>
                  {
                    Cookie.get('user') ?
                      data.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                        <Tooltip title={data.isFavorite ? t('jobDetail.alreadyFavorite') : t('jobDetail.addFavorite') }>
                          <Button type="primary" icon={data.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(data.id, data.isFavorite)} ghost />
                        </Tooltip>
                      : data.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] !== 'student.binawan.ac.id' ?
                        null
                      :
                        <Button type="link" onClick={() => handleFavorite(data.id, data.isFavorite)}>{data.isFavorite ? <StarFilled className="fs-16" /> : <StarOutlined className="fs-16" />} {`${t('jobDetail.favorite')}`}</Button>
                    :
                      null
                  }
                  {
                    data.externalLink ?
                      <a href={data.externalLink} target="_blank" rel="noreferrer">
                        <Button type="primary">{t('jobDetail.applyNow')}</Button>
                      </a>
                    : data.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                      data.isApply ?
                        <Text className="float-right fs-14 primary-color"><CheckOutlined className="fs-16" /> {`${t('jobDetail.applied')}`}</Text>
                      :
                        <Button type="primary" className="width-100 float-right" onClick={Cookie.get('user') ? showModal : () => onClickApply(window.location.pathname)}>{`${t('jobDetail.applyNow')}`}</Button>
                    : data.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] !== 'student.binawan.ac.id' ?
                      <Tooltip title={t('jobList.typeWh') }>
                        <Button type="primary" disabled>{t('jobDetail.applyNow')}</Button>
                      </Tooltip>
                    :
                      data.isApply ?
                        <Text className="float-right fs-14 primary-color"><CheckOutlined className="fs-16" /> {`${t('jobDetail.applied')}`}</Text>
                      :
                        <Button type="primary" className="width-100 float-right" onClick={Cookie.get('user') ? showModal : () => onClickApply(`/job/detail/${data.slug}`)}>{`${t('jobDetail.applyNow')}`}</Button>
                  }
                  <Dropdown overlay={() => menu(`${path}/job/detail/${data.slug}`)} trigger={['click']} placement="bottomLeft">
                    <Button className='fs-15' type="link"><ShareAltOutlined className='fs-16' /> {`${t('jobDetail.share')}`}</Button>
                  </Dropdown>
                </Space>
                <Modal 
                  width={1000} 
                  title={`Apply to ${data.companyName}`} 
                  visible={visible} 
                  onCancel={handleCancel}
                  footer={false}
                  maskClosable={false}
                  destroyOnClose
                >
                    {
                      getSteps?.data?.listStepsApply?.length > 1 ?
                        <Steps className="mb-16" size="small" current={current}>
                          {
                            getSteps?.data?.listStepsApply?.map((item, i) => 
                              <Step key={i} title={item.name} icon={<Icon type={item.icon} />} />
                            )
                          }
                        </Steps>
                      :
                        null
                    }
                    <Form 
                      layout="vertical"
                      ref={formRef}
                      onFinish={
                        editContact || editPersonal || editEducation ?
                          onUpdate
                        : getSteps?.data?.listStepsApply[current].name === 'Review' ? 
                          onFinish 
                        : onNext
                      }
                    >
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Contact Detail' ?
                          <Contact {...props} />
                        :
                          null
                      }
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Personal Info' ?
                          <PersonalInfo {...props} />
                        :
                          null
                      }
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Education' ?
                          <Education {...props} />
                        :
                          null
                      }
                      {
                        getSteps?.data?.listStepsApply[current].name === 'Review' ?
                          <Review {...props} />
                        :
                          null
                      }

                      <Divider />

                      <Row>
                        <Col span={24}>
                          <Space className="float-right">
                            {
                              current > 0 ?
                                editContact || editPersonal || editEducation || getSteps?.data?.listStepsApply[current].name === 'Review' ?
                                  null
                                :
                                  <Button key="back" type="link" onClick={prev}>
                                    Back
                                  </Button>
                              :
                                null
                            }
                            <Button key="next" type="primary" htmlType="submit" loading={submitLoading}>
                              {
                                editContact || editPersonal || editEducation ?
                                  'Update'
                                : getSteps?.data?.listStepsApply[current].name === 'Education' ?
                                  'Review'
                                : getSteps?.data?.listStepsApply[current].name === 'Review' ? 
                                  'Submit' 
                                :
                                  'Next'
                              }
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                </Modal>
              </Col>
            </Row>
          </Card>
          
          {
            data.placementType === 'INTERNATIONAL' ?
              <Card 
                className="mb-16 box-shadow"
                bordered={false}
              >
                <Tabs defaultActiveKey="1" type='card'>
                  <TabPane tab="Candidate Requirments" key="1">
                    <Row className="mb-16">
                      <Col span={12}>
                        <Space direction="vertical">
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.gender')}`}</Text>
                            <Text className="dark-color" strong>{gender}</Text>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.minimumEducationLevel')}`}</Text>
                            <Text className="dark-color" strong>{data.educationLevelName}</Text>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.skills')}`}</Text>
                            <Text className="dark-color" strong>{skill}</Text>
                          </Space>
                        </Space>
                      </Col>
                      <Col span={12}>
                        <Space direction="vertical">
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.ageRange')}`}</Text>
                            <Text className="dark-color" strong>{`${data.ageStart} - ${data.ageEnd}`}</Text>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.fieldsOfStudy')}`}</Text>
                            <Text className="dark-color" strong>{data.fieldOfStudy}</Text>
                          </Space>
                        </Space>
                      </Col>
                      {
                        data.listOtherRequirement ?
                          data.listOtherRequirement.map((item, i) => 
                            <Col key={i} span={24}>
                              <Space direction="vertical" size={0}>
                                <Text className="fs-12 dark-color">{item.children}</Text>
                                <Text className="dark-color" strong>{data[item.value]}</Text>
                              </Space>
                            </Col>
                          )
                        :
                          null
                      }
                    </Row>
                  </TabPane>
                  {
                    data.jobDescription ?
                      <TabPane tab="Job Description" key="2">
                        <Row className="mb-16">
                          <Col span={24}>
                            <Space direction="vertical" size={16}>
                              <Text className="fs-15 dark-color" strong>{`${t('jobDetail.jobDescription')}`}</Text>
                              {
                                data.jobDescription ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.jobDescription }}></div>
                                :
                                  null
                              }
                            </Space>
                          </Col>
                        </Row>
                      </TabPane>
                    : null
                  }
                  <TabPane tab="Terms and Conditions" key="3">
                    <Row className="mb-16">
                      <Col span={12}>
                        <Space direction="vertical">
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.durationOfContract')}`}</Text>
                            <Space size={4}>
                              <Text className="dark-color" strong>{data.durationOfContract}</Text>
                              <Text className="dark-color">{isContractExtendable}</Text>
                            </Space>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.workingDays')}`}</Text>
                            <Text className="dark-color" strong>{data.workingDay}</Text>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.probationPeriod')}`}</Text>
                            <Text className="dark-color" strong>{data.probationPeriod}</Text>
                          </Space>
                        </Space>
                      </Col>
                      <Col span={12}>
                        <Space direction="vertical">
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.anualLeave')}`}</Text>
                            <Space size={4}>
                              <Text className="dark-color" strong>{data.annualLeave}</Text>
                              <Text className="dark-color">{isPaidAnnualLeave}</Text>
                            </Space>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.workingHours')}`}</Text>
                            <Text className="dark-color" strong>{data.workingHour}</Text>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12 dark-color">{`${t('jobDetail.workingOvertime')}`}</Text>
                            <Text className="dark-color" strong>{isWorkingOvertime}</Text>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </TabPane>
                  {
                    data?.listBenefit?.length > 0 ?
                      <TabPane tab="Job Benefit" key="4">
                        <Row className="mb-16">
                          <Col span={24}>
                            <Space direction="vertical">
                              {
                                data?.listBenefit?.map((item, i) => 
                                  <Space key={i} direction="vertical" size={0}>
                                    <Text className="dark-color" strong>{item.name}</Text>
                                    {
                                      item.status ?
                                        <Space size={4}>
                                          <Text className="fs-12 dark-color" type="secondary">Allowance,</Text>
                                          <Text className="fs-12 primary-color">{`${item.allowance.toLocaleString()} ${data.currencyCode}`}</Text>
                                        </Space>
                                      :
                                        <Text className="fs-12 dark-color">Provided</Text>
                                    }
                                  </Space>
                                )
                              }
                            </Space>
                          </Col>
                        </Row>
                      </TabPane>
                    : null
                  }
                </Tabs>
              </Card>
            : null
          }
          {
            data.placementType === 'DOMESTIC' && (data.jobRequirement || data.jobDescription || data.benefit || data?.listJobImage?.length > 0) ?
              <Card 
                className="mb-16 box-shadow"
                bordered={false}
              >
                <Tabs defaultActiveKey="1" type='card'>
                  {
                    data.jobRequirement ?
                      <TabPane tab={`${t('jobDetail.jobRequirments')}`} key="1">
                        <Row className="mb-16">
                          <Col span={24}>
                            <Space direction="vertical" size={16}>
                              <Text className="fs-15 dark-color" strong>{`${t('jobDetail.jobRequirments')}`}</Text>
                              {
                                data.jobDescription ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.jobRequirement }}></div>
                                :
                                  null
                              }
                            </Space>
                          </Col>
                        </Row>
                      </TabPane>
                    : null
                  }
                  {
                    data.jobDescription ?
                      <TabPane tab={`${t('jobDetail.jobDescription')}`} key="2">
                        <Row className="mb-16">
                          <Col span={24}>
                            <Space direction="vertical" size={16}>
                              <Text className="fs-15 dark-color" strong>{`${t('jobDetail.jobDescription')}`}</Text>
                              {
                                data.jobDescription ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.jobDescription }}></div>
                                :
                                  null
                              }
                            </Space>
                          </Col>
                        </Row>
                      </TabPane>
                    : null
                  }
                  {
                    data.benefit ?
                      <TabPane tab={`${t('jobDetail.jobBenefit')}`} key="3">
                        <Row className="mb-16">
                          <Col span={24}>
                            <Space direction="vertical" size={16}>
                              <Text className="fs-15 dark-color" strong>{`${t('jobDetail.jobBenefit')}`}</Text>
                              {
                                data.jobDescription ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.benefit }}></div>
                                :
                                  null
                              }
                            </Space>
                          </Col>
                        </Row>
                      </TabPane>
                    : null
                  }
                </Tabs>
              </Card>
            : null
          }

          {/* Event Pekerjaan */}
          {
            data?.listJobEvent?.length > 0 ? 
              <Card 
                className="mb-16 box-shadow"
                title={`${t('jobDetail.eventJob')}`}
                bordered={false}
                bodyStyle={{ padding: 0 }}
              >
                <List
                  className="mt-16"
                  size="large"
                  // pagination={{
                  //   style: { textAlign: 'center', paddingBottom: 24 },
                  //   pageSize: data?.listJobSimiliar?.meta?.perpage,
                  //   total: data?.listJobSimiliar?.meta?.total,
                  //   current: data?.listJobSimiliar?.meta?.page,
                  //   onChange: (pageNumber) => onChangePagination(pageNumber)
                  // }}
                  dataSource={data.listJobEvent}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <Row className="width-100" align="middle">
                        <Col className="col-img-job" xs={24} sm={4} md={4}>
                          {/* <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}> */}
                            <Image
                              src={item.thumbnailImageUrl ? item.thumbnailImageUrl : EmptyLogo}
                              preview={false}
                              width={100}
                              height={100}
                              style={{ objectFit: 'cover' }}
                            />
                          {/* </Link> */}
                        </Col>
                        <Col xs={24} sm={13} md={12}>
                          <Space direction="vertical" size={0}>
                            <Text className="dark-color font-weight-500 fs-17">{item.title}</Text>
                            <Text className="fs-13 dark-color">{item.eventDay}, {moment(item.eventDate, 'DD/MM/YYYY').format('DD MMM YYYY')} {moment(item.startTime, 'HH:mm:ss').format('HH:mm:ss')} - {moment(item.endTime, 'HH:mm:ss').format('HH:mm:ss')}</Text>
                            <Text className="fs-13 dark-color">{item.jobEventType}</Text>
                          </Space>
                        </Col>
                        <Col className='mt-16 pr-4' span={12}>
                          <Link to={Cookie.get('user') ? `/candidate/event/detail/${item.id}` : `/event/detail/${item.id}`}>
                            <Button type="primary" className="border-radius-6" block>{`${t('jobDetail.infoEvent')}`}</Button>
                          </Link>
                        </Col>
                        <Col className='mt-16 pl-4' span={12}>
                          <Dropdown overlay={() => menu(`${path}/event/detail/${item.id}`)} trigger={['click']} placement="bottomLeft">
                            <Button type="primary" className='border-radius-6' ghost block><ShareAltOutlined className='fs-16' />{`${t('jobDetail.share')}`} </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Card>
            : null
          }

          {/* Our Job Story */}
          {
            data?.listJobStory?.length > 0 ? 
              <Card 
                className="mb-16 box-shadow" 
                title={`${t('jobDetail.ourJobStory')}`}
                extra={
                  <Space size={8}>
                    <Text className="dark-color cursor-pointer" onClick={previous}><LeftCircleFilled style={{ marginTop: 4 }} /> {`${t('jobDetail.previous')}`}</Text>
                    <Text className="dark-color cursor-pointer" onClick={next}>{`${t('jobDetail.next')}`} <RightCircleFilled style={{ marginTop: 4 }} /></Text>
                  </Space>
                }
                >
                  <Carousel ref={sliderRef} {...settings}>
                    {
                      data?.listJobStory?.map((item,i) => 
                        <Row key={i}>
                          <Col span={24}>
                            <div>
                              <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}>
                                {
                                  item.fileExtension === 'mp4' ?
                                    <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                                      <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                                    </Card>
                                  :
                                    <Image
                                      src={item.fileUrl}
                                      preview={false}
                                      width={'100%'}
                                      height={160}
                                      style={{ objectFit: 'cover' }}
                                    />
                                }
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                  </Carousel>
              </Card>
            : null
          }

          <Divider />

          {/* Halaman Perusahaan */}
          <Card 
            className="mb-16 box-shadow"
            bordered={false}
          >
            <Row gutter={32}>
              <Col xxl={3} xl={5} lg={6}>
                <Image
                  src={data.companyLogoUrl ? data.companyLogoUrl : EmptyLogo}
                  preview={false}
                  width={'100%'}
                  style={{ objectFit: 'contain' }}
                />
              </Col>
              <Col xxl={21} xl={19} lg={18}>
                <Space direction='vertical' size={8} style={{ minWidth: '100%' }}>
                  <Row>
                    <Col span={24}>
                      <Text className="fs-16 dark-color" strong>{`${t('jobDetail.overview')}`}</Text>
                    </Col>
                  </Row>
                  {
                    data.companyAbout ? 
                      <Row>
                        <Col span={24}>
                          <Text className="fs-15 dark-color">{data.companyAbout}</Text>
                        </Col>
                      </Row>
                    : null
                  }
                  {
                    data.companyWebsite ? 
                      <Row className='width-100 mb-min-8'>
                        <Col xs={8} sm={6} xl={4}>
                          <Text className="fs-15 dark-color">{`${t('jobDetail.website')}`}</Text>
                        </Col>
                        <Col xs={16} sm={18} xl={20}>
                          <a href={data.companyWebsite.includes("https://") || data.companyWebsite.includes("http://") ? `${data.companyWebsite}` : `https://${data.companyWebsite}`} target="_blank" rel="noreferrer">
                            <Text className="fs-15 dark-color" strong>{data.companyWebsite ? data.companyWebsite : '-'}</Text>
                          </a>
                        </Col>
                      </Row>
                    : null
                  }
                  {
                    data.companyJobIndustryTitle ? 
                      <Row className='mb-min-8'>
                        <Col xs={8} sm={6} xl={4}>
                          <Text className="fs-15 dark-color">{`${t('jobDetail.industry')}`}</Text>
                        </Col>
                        <Col xs={16} sm={18} xl={20}>
                          <Text className="fs-15 dark-color" strong>{data.companyJobIndustryTitle ? data.companyJobIndustryTitle : '-'}</Text>
                        </Col>
                      </Row>
                    : null
                  }
                  {
                    data?.companySpecialties ? 
                      <Row className='mb-min-8'>
                        <Col xs={8} sm={6} xl={4}>
                          <Text className="fs-15 dark-color">{`${t('jobDetail.specialties')}`}</Text>
                        </Col>
                        <Col xs={16} sm={18} xl={20}>
                          <Text className="fs-15 dark-color" strong>{data.companySpecialties ? data.companySpecialties : '-'}</Text>
                        </Col>
                      </Row>
                    :
                      null
                  }
                  {
                    data.companyTotalEmployee ? 
                      <Row>
                        <Col xs={8} sm={6} xl={4}>
                          <Text className="fs-15 dark-color">{`${t('jobDetail.companySize')}`}</Text>
                        </Col>
                        <Col xs={16} sm={18} xl={20}>
                          <Text className="fs-15 dark-color" strong>{data.companyTotalEmployee ? data.companyTotalEmployee : '-'}</Text>
                        </Col>
                      </Row>
                    : null
                  }
                </Space>
              </Col>
              <Col span={24}>
                {
                  listCompanyMediaAll?.length > 0 ?
                    <Row>
                      <Col className='mb-16 mt-16' span={24}>
                        <Text className="fs-16 dark-color" strong>{t('jobDetail.companyPhotos')}</Text>
                      </Col>
                      {
                        listCompanyMediaAll?.length > 3 ?
                          <Col className='text-align-center' span={2}>
                            <LeftCircleOutlined className='dark-color' onClick={previousMedia} style={{ fontSize: 26, marginTop: 75 }} />
                          </Col>
                        : null
                      }
                      <Col className='text-align-center' span={20}>
                        <Carousel ref={mediaRef} {...settings}>
                          {
                            listCompanyMediaAll?.map((item,i) => 
                              <Row key={i} gutter={8}>
                                <Col span={24}>
                                  <div>
                                    <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(listCompanyMediaAll,i)} bodyStyle={{ padding: 8 }}>
                                      {
                                        i === 0 ?
                                          <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                                            <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                                          </Card>
                                        :
                                          <Image
                                            src={item.fileUrl}
                                            preview={false}
                                            width={'100%'}
                                            height={160}
                                            style={{ objectFit: 'cover' }}
                                          />
                                      }
                                    </Card>
                                  </div>
                                </Col>
                              </Row>
                            )
                          }
                        </Carousel>
                      </Col>
                      {
                        listCompanyMediaAll?.length > 3 ?
                          <Col className='text-align-center' span={2}>
                            <RightCircleOutlined className='dark-color' onClick={nextMedia} style={{ fontSize: 26, marginTop: 75 }} />
                          </Col>
                        : null
                      }
                    </Row>
                  : null
                }
              </Col>
            </Row>
          </Card>

          {/* Informasi Negara Tujuan */}
          {
            data.jobCountryName && data.jobCountryImageUrl && data.jobCountryInformation ?
              <Card
                className="mb-16 box-shadow"
                title={t('jobDetail.destinationCountry')}
                bordered={false}
              >
                <Row className="mb-16">
                  <Col span={24}>
                    <Image
                      src={data.jobCountryImageUrl}
                      preview={false}
                      width={'100%'}
                      style={{ objectFit: 'contain' }}
                    />
                  </Col>
                  <Col span={24} >
                    <Space className="width-100" direction="vertical" >
                      <Text className="fs-16 dark-color" strong>{data.jobCountryName}</Text>
                      <Text className="fs-15 dark-color">{data.jobCountryInformation}</Text>
                    </Space>
                  </Col>
                </Row>
              </Card>
            : null
          }

          {/* FaQ'S */}
          {
            data?.listFaq?.length > 0 ?
              <Card  
                className="mb-16 box-shadow"
                title="FaQ'S"
                bordered={false}
              >
                <Row align="center">
                  <Text strong>{t('jobDetail.faq')}</Text>
                </Row>
                <Collapse className='faq-job-detail' bordered={false} expandIconPosition="right" expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />} style={{ background: 'transparent' }}>
                  {
                    data?.listFaq?.map((item,i) => 
                      <Panel 
                        key={i}
                        header={
                          <Space align="center">
                            <Text className='primary-color fs-20'>•</Text>
                            <Text className='font-weight-500 dark-color fs-14'>{item.question}</Text>
                          </Space>
                        }
                      >
                        <p>{item.answer}</p>
                      </Panel>
                    )
                  }
                </Collapse>
              </Card>
            : null
          }
        </Col>
        
        {
          data?.listJobSimiliar?.length > 0 ? 
            <Col span={24}>
              {/* {
                getJob?.loading ?
                  <Skeleton.Input style={{ width: '100%' }} active />
                :
                  <Text className="dark-color fs-13 mb-32" style={{ paddingLeft: xs ? 16 : 0 }}>{`Showing ${data?.listJobSimiliar?.meta?.total === 0 ? data?.listJobSimiliar?.meta?.total : (data?.listJobSimiliar?.meta?.page*data?.listJobSimiliar?.meta?.perpage)-(data?.listJobSimiliar?.meta?.perpage-1)}-${data?.listJobSimiliar?.meta?.page === data?.listJobSimiliar?.meta?.pages || data?.listJobSimiliar?.meta?.total === 0 ? data?.listJobSimiliar?.meta?.total : data?.listJobSimiliar?.meta?.page*data?.listJobSimiliar?.meta?.perpage} of ${data?.listJobSimiliar?.meta?.total} job openings`}</Text>
              } */}
              <Card 
                className="mb-16 box-shadow"
                title={t('jobDetail.similiarJobs')}
                bordered={false}
                bodyStyle={{ padding: 8 }}
              >
                <List
                  className="mt-16"
                  size="large"
                  // pagination={{
                  //   style: { textAlign: 'center', paddingBottom: 24 },
                  //   pageSize: data?.listJobSimiliar?.meta?.perpage,
                  //   total: data?.listJobSimiliar?.meta?.total,
                  //   current: data?.listJobSimiliar?.meta?.page,
                  //   onChange: (pageNumber) => onChangePagination(pageNumber)
                  // }}
                  dataSource={data.listJobSimiliar}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <Row className="width-100">
                        <Col className="col-img-job" xs={24} sm={4} md={4}>
                          {/* <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}> */}
                            <Image
                              src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                              preview={false}
                              width={100}
                              height={100}
                              onClick={() => onClickToDetail(item.slug)}
                              style={{ objectFit: 'contain' }}
                            />
                          {/* </Link> */}
                        </Col>
                        <Col xs={24} sm={13} md={12}>
                          <Space direction="vertical" size={0}>
                            <Text className="title-job dark-color fs-17 cursor-pointer" onClick={() => onClickToDetail(item.slug)}>{item.title}</Text>
                            <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                              <Text className="company-job fs-14 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                            </Link>
                            <Text className="fs-14 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                            <Text className="fs-14 primary-color">
                              {
                                item.isDisplaySalary ?
                                  item.maxSalary ? 
                                    `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                  : 
                                    `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                :
                                  'Salary not disclosed'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col className="width-100" xs={24} sm={7} md={8}>
                          <Space className="width-100" direction="vertical" size={16}>
                            <Text className="fs-14 dark-color">{`Posted on ${item.postDate}`}</Text>
                            {
                              Cookie.get('user') ?
                                <Space className="width-100" size={8}>
                                  <Tooltip title={item.isFavorite ? t('jobDetail.alreadyFavorite') : t('jobDetail.addFavorite') }>
                                    <Button type="primary" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                  </Tooltip>
                                  {
                                    item.isApply ?
                                      <Text className="fs-14 primary-color"><CheckOutlined /> {`${t('jobDetail.applied')}`}</Text>
                                    :
                                      <Button className="width-100" type="primary" onClick={() => onClickToDetail(item.slug)}>{`${t('jobDetail.apply')}`}</Button>
                                  }         
                                </Space>
                              :
                                <Button className="width-100" type="primary" onClick={() => onClickApply(`job/detail/${item.slug}`)}>{`${t('jobDetail.apply')}`}</Button>
                            }
                          </Space>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          : null
        }
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(DetailJobMobile)